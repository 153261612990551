import { useMutation, useQueryClient } from 'react-query';
import { api } from 'utils/api';

export const useLogout = () => {
    const queryClient = useQueryClient();

    return useMutation('logout', () => api.post('logout'), {
        onSuccess: () => {
            queryClient.removeQueries('user');
            localStorage.removeItem('token');
        }
    });
};
