import React from 'react';
import { useSelector } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, StyledEngineProvider } from '@material-ui/core';

// routing
import Routes from './routes';

// defaultTheme
import themes from './themes';

// project imports
import NavigationScroll from './layout/NavigationScroll';

// ===========================|| APP ||=========================== //

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000)
        }
    }
});

const App = () => {
    const customization = useSelector((state) => state.customization);

    return (
        <QueryClientProvider client={queryClient}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={themes(customization)}>
                    <CssBaseline />
                    <NavigationScroll>
                        <Routes />
                    </NavigationScroll>
                </ThemeProvider>
            </StyledEngineProvider>
            <ReactQueryDevtools />
        </QueryClientProvider>
    );
};

export default App;
