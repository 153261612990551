import React, { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDre = Loadable(lazy(() => import('views/dashboard/dre')));
const DashboardDfc = Loadable(lazy(() => import('views/dashboard/dfc')));
const DashboardBillsToPay = Loadable(lazy(() => import('views/dashboard/billsToPay')));
const DashboardBillsToReceive = Loadable(lazy(() => import('views/dashboard/billsToReceive')));

// views routing
const GroupsIndex = Loadable(lazy(() => import('views/groups/index')));
const GroupsCreate = Loadable(lazy(() => import('views/groups/create')));
const GroupsEdit = Loadable(lazy(() => import('views/groups/edit')));
const GroupsInfo = Loadable(lazy(() => import('views/groups/info')));
const GroupsEntities = Loadable(lazy(() => import('views/groups/entities')));
const GroupsEntitiesEdit = Loadable(lazy(() => import('views/groups/editEntity')));

const UniversalAccounts = Loadable(lazy(() => import('views/universalAccounts/index')));
const UniversalAccountsCreate = Loadable(lazy(() => import('views/universalAccounts/create')));
const UniversalAccountsUpdate = Loadable(lazy(() => import('views/universalAccounts/edit')));

const DreIndex = Loadable(lazy(() => import('views/dre/index')));
const DreCreate = Loadable(lazy(() => import('views/dre/create')));
const DreEdit = Loadable(lazy(() => import('views/dre/edit')));

const DfcIndex = Loadable(lazy(() => import('views/dfc/index')));
const DfcCreate = Loadable(lazy(() => import('views/dfc/create')));
const DfcEdit = Loadable(lazy(() => import('views/dfc/edit')));

const DeParaIndex = Loadable(lazy(() => import('views/fromTo/index')));

const UserPermissionIndex = Loadable(lazy(() => import('views/userPermission/index')));
const UserPermissionCreate = Loadable(lazy(() => import('views/userPermission/create')));
const UserPermissionEdit = Loadable(lazy(() => import('views/userPermission/edit')));

const UsersIndex = Loadable(lazy(() => import('views/users/index')));
const UsersCreate = Loadable(lazy(() => import('views/users/create')));

const PerfilIndex = Loadable(lazy(() => import('views/perfil/index')));
const PerfilUpdatePassword = Loadable(lazy(() => import('views/perfil/updatePassword')));
const PerfilEdit = Loadable(lazy(() => import('views/perfil/edit')));

// ===========================|| MAIN ROUTING ||=========================== //

const MainRoutes = () => [
    {
        path: '/',
        element: <MainLayout />,
        children: [
            {
                path: '/dashboard/dre',
                element: <DashboardDre />
            },
            {
                path: '/dashboard/dfc',
                element: <DashboardDfc />
            },
            {
                path: '/dashboard/contas-pagar',
                element: <DashboardBillsToPay />
            },
            {
                path: '/dashboard/contas-receber',
                element: <DashboardBillsToReceive />
            },
            {
                path: '/grupos-empresariais',
                element: <GroupsIndex />
            },
            {
                path: '/grupos-empresariais/cadastrar',
                element: <GroupsCreate />
            },
            {
                path: '/grupos-empresariais/editar',
                element: <GroupsEdit />
            },
            {
                path: '/grupos-empresariais/info',
                element: <GroupsInfo />
            },
            {
                path: '/grupos-empresariais/entidades',
                element: <GroupsEntities />
            },
            {
                path: '/grupos-empresariais/entidades/editar',
                element: <GroupsEntitiesEdit />
            },
            {
                path: '/contas-universais',
                element: <UniversalAccounts />
            },
            {
                path: '/contas-universais/cadastrar',
                element: <UniversalAccountsCreate />
            },
            {
                path: '/contas-universais/editar',
                element: <UniversalAccountsUpdate />
            },
            {
                path: '/dre',
                element: <DreIndex />
            },
            {
                path: '/dre/cadastrar',
                element: <DreCreate />
            },
            {
                path: '/dre/editar',
                element: <DreEdit />
            },
            {
                path: '/dfc',
                element: <DfcIndex />
            },
            {
                path: '/dfc/cadastrar',
                element: <DfcCreate />
            },
            {
                path: '/dfc/editar',
                element: <DfcEdit />
            },
            {
                path: '/de-para',
                element: <DeParaIndex />
            },
            {
                path: '/users',
                element: <UsersIndex />
            },
            {
                path: '/users/cadastrar',
                element: <UsersCreate />
            },
            {
                path: '/perfil',
                element: <PerfilIndex />
            },
            {
                path: '/perfil/alterar-senha',
                element: <PerfilUpdatePassword />
            },
            {
                path: '/perfil/editar',
                element: <PerfilEdit />
            },
            {
                path: '/perfil-usuario',
                element: <UserPermissionIndex />
            },
            {
                path: '/perfil-usuario/cadastrar',
                element: <UserPermissionCreate />
            },
            {
                path: '/perfil-usuario/editar',
                element: <UserPermissionEdit />
            }
        ]
    }
];

export default MainRoutes;
