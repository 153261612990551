import React from 'react';
import * as tablerIcon from '@tabler/icons';

// material-ui
import { Typography } from '@material-ui/core';

// project imports
import NavGroup from './NavGroup';
import { useUser } from 'hooks/User/useUser';

// ===========================|| SIDEBAR MENU LIST ||=========================== //

function getUrl(id) {
    if (id === 1) return '/dashboard/dre';
    if (id === 2) return '/dashboard/dfc';
    if (id === 3) return '/dashboard/contas-pagar';
    if (id === 4) return '/dashboard/contas-receber';
    if (id === 5) return '/grupos-empresariais';
    if (id === 6) return '/contas-universais';
    if (id === 7) return '/dre';
    if (id === 8) return '/dfc';
    if (id === 9) return '/de-para';
    if (id === 10) return '/perfil-usuario';
    return '';
}

const MenuList = () => {
    const { data } = useUser(true);
    const [menuItem, setMenuItem] = React.useState();

    React.useEffect(() => {
        const itemsArray = [];

        if (data && data.length > 0) {
            if (data?.some((module) => module.title === 'Relatórios Gerenciais'))
                itemsArray.push({
                    id: 'managementReports',
                    title: 'Relatórios Gerenciais',
                    type: 'group',
                    children: data
                        ?.filter((module) => module.title === 'Relatórios Gerenciais')
                        .map((module) => ({
                            id: module.id,
                            title: module.name,
                            url: getUrl(module.id),
                            icon: tablerIcon[module.icon_name],
                            type: 'item',
                            breadcrumbs: false
                        }))
                });

            if (data?.some((module) => module.title === 'Páginas'))
                itemsArray.push({
                    id: 'pages',
                    title: 'Páginas',
                    type: 'group',
                    children: data
                        .filter((module) => module.title === 'Páginas')
                        .map((module) => ({
                            id: module.id,
                            title: module.name,
                            url: getUrl(module.id),
                            icon: tablerIcon[module.icon_name],
                            type: 'item'
                        }))
                });

            setMenuItem({
                items: itemsArray
            });
        }
    }, [data]);

    if (menuItem) {
        return menuItem.items.map((item) => {
            if (item.type === 'group') {
                return <NavGroup key={item.id} item={item} />;
            }

            return (
                <Typography key={item.id} variant="h6" color="error" align="center">
                    Menu Items Error
                </Typography>
            );
        });
    }
    return (
        <Typography key={1} variant="h6" mt={4} align="center">
            Não existem permissões
        </Typography>
    );
};
export default MenuList;
