import { useUser } from 'hooks/User/useUser';
import { useCallback } from 'react';

// Check user has permission to access a resource
export function usePermission() {
    const { data } = useUser();

    const hasPermission = useCallback(
        (permissionsString) => {
            const permissionsArr = permissionsString.split('|');

            if (Array.isArray(data?.permissions)) {
                const returnValue = permissionsArr.map((element) => data?.permissions.includes(element));

                return returnValue.includes(true);
            }
            return false;
        },
        [data?.permissions]
    );

    return { hasPermission };
}
