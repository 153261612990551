import { useQuery } from 'react-query';
import { api, setApiToken } from 'utils/api';
import PropTypes from 'prop-types';

export const useUser = (onlyModules = false) =>
    useQuery('user', ({ signal }) => api.get('me', { signal }), {
        select: ({ data: { payload } }) => (onlyModules ? payload.modules : payload.user),
        onError: (err) => {
            if (err.code === '401') {
                setApiToken(null);
            }
        }
    });

useUser.propTypes = {
    onlyModules: PropTypes.bool
};
