import { useRoutes } from 'react-router-dom';
import React from 'react';
// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import { usePermission } from 'utils/hasPermission';

// ===========================|| ROUTING RENDER ||=========================== //

export default function ThemeRoutes() {
    const { hasPermission } = usePermission();

    const routing = useRoutes([AuthenticationRoutes, ...MainRoutes(hasPermission)]);

    return <>{routing}</>;
}
